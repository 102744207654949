import cookies from 'js-cookie';
import { useRouter } from 'next/navigation';
import { useMemo } from 'react';
import usePurchaseStore from 'shared/store/purchase';
import useRefundStore from 'shared/store/refund';

import { useAgent } from 'shared/use-agent/use-agent';
import { useStorage } from 'shared/use-storage';

export const useSidebar = () => {
  const router = useRouter();
  const { data: agent } = useAgent();
  const storage = useStorage();
  const { reset: resetPurchase } = usePurchaseStore();
  const { reset: resetRefund } = useRefundStore();

  const currentShop = useMemo(
    () => agent?.stores.find((s) => s.store.id === Number(storage?.getItem('shopId'))),
    [agent, storage]
  );

  const onLogoutClick = () => {
    storage?.removeItem('shopId');
    cookies.remove('token');
    router.push('/auth/sign-in');
  };

  const resetStores = () => {
    resetPurchase();
    resetRefund();
  };

  return { agent, currentShop: currentShop?.store, onLogoutClick, resetStores };
};
