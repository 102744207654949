export const canUseDOM = !!(
  typeof window !== 'undefined' &&
  window.document &&
  window.document.createElement
);

const store: Record<string, string> = {};

export const setStoreItem = (name: string, value: string) => {
  try {
    localStorage.setItem(name, value);
  } catch {
    store[name] = value;
  }
};
export const getStoreItem = (name: string) => {
  try {
    return localStorage.getItem(name);
  } catch {
    return store[name];
  }
};

export const removeStoreItem = (name: string) => {
  try {
    localStorage.removeItem(name);
  } catch {
    delete store[name];
  }
};

export const useStorage = () => {
  if (canUseDOM) {
    try {
      const key = `__storage__test`;
      window.localStorage.setItem(key, '');
      window.localStorage.removeItem(key);
      return window.localStorage;
    } catch (e) {
      return {
        setItem: setStoreItem,
        getItem: getStoreItem,
        removeItem: removeStoreItem,
      };
    }
  }

  return null;
};
