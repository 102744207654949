'use client';

import Box from '@mui/material/Box';
import { PropsWithChildren } from 'react';

export const Layout = ({ children, sidebar = false }: PropsWithChildren<{ sidebar?: boolean }>) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100dvh',
        background: '#F9F8F8',
        '@media (min-width: 720px)': {
          px: 6,
          pb: 6,
          pl: sidebar ? '328px' : 6,
        },
      }}
    >
      {children}
    </Box>
  );
};

export const AuthLayout = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        minHeight: '100dvh',
        '@media (min-width: 720px)': {
          maxWidth: '480px',
          mx: 'auto',
          justifyContent: 'flex-start',
          pt: 8,
        },
      }}
    >
      {children}
    </Box>
  );
};

export const LayoutInner = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Box
      px={2}
      sx={{
        '@media (min-width: 720px)': {
          px: 0,
        },
      }}
    >
      {children}
    </Box>
  );
};

export const FormLayout = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Box
      sx={{
        '@media (min-width: 720px)': {
          maxWidth: '720px',
        },
      }}
    >
      {children}
    </Box>
  );
};
