import { useQuery } from '@tanstack/react-query';
import { http } from 'shared/api/http';
import { AgentResponseType } from './types';

const getAgentRequest = () =>
  http.get<AgentResponseType>('/api/loans/v1/agent').then((r) => r.data.agent);

export const useAgent = () => {
  return useQuery({
    queryKey: ['agent'],
    queryFn: () => getAgentRequest(),
  });
};
