export enum PurchaseSteps {
  purchaseStart = 'purchaseStart',
  enterUserInfo = 'enterUserInfo',
  termChoise = 'termChoise',
  uploadClientDocs = 'uploadClientDocs',
  agreements = 'agreements',
  purchaseFinish = 'purchaseFinish',
}

///под страну дженерик можно сделать
export type UserInfoType = {
  phone: string;
};

export type UpdateUserInfoType = Partial<UserInfoType>;
