'use client';

declare global {
  interface Window {
    MSStream: any;
    opera: any;
  }
}

export enum MobileOS {
  windowsPhone = 'windowsPhone',
  android = 'android',
  ios = 'ios',
  unknown = 'unknown',
}

export function getMobileOS() {
  if (typeof window !== 'undefined') {
    const userAgent = window.navigator.userAgent || window.navigator.vendor || window.opera;

    if (/windows phone/i.test(userAgent)) {
      return MobileOS.windowsPhone;
    }

    if (/android/i.test(userAgent)) {
      return MobileOS.android;
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return MobileOS.ios;
    }
  }

  return MobileOS.unknown;
}

export function isPWA() {
  return (
    window.matchMedia('(display-mode: standalone)').matches ||
    // @ts-ignore
    window.navigator.standalone ||
    document.referrer.includes('android-app://')
  );
}

export function isMobile() {
  return getMobileOS() !== MobileOS.unknown;
}
