import { sendGTMEvent } from '@next/third-parties/google';
import { isMobile, isPWA } from './get-mobile-os';

export const sendGtmEvent = (action: string, customData?: Record<string, string>) => {
  return sendGTMEvent({
    event: 'webmerchapp',
    trackEvent: {
      action,
      type: isPWA() ? 'pwa' : isMobile() ? 'mobile' : 'desktop',
      country: process.env.COUNTRY || '_',
      ...customData,
    },
  });
};
