import axios from 'axios';
import axiosCaseConverter from 'axios-case-converter';
import cookies from 'js-cookie';
import * as Sentry from '@sentry/nextjs';

export const http = axiosCaseConverter(
  axios.create({
    baseURL: process.env.NEXT_PUBLIC_API_BACKEND_URL,
    withCredentials: false,
    timeout: 60e3,
  })
);

http.interceptors.request.use(async (config) => {
  const token = cookies.get('token');
  if (token) {
    config.headers.set('Authorization', token);
  }
  return config;
});

http.interceptors.response.use(
  async (response) => {
    return response;
  },
  async (error) => {
    if (error.response.status >= 500 && error.response.status < 600) {
      // window.location.replace('/error-500');
      Sentry.captureException(error, {
        level: 'error',
        extra: {
          type: 'axios error',
        },
      });
    }
    if (error.response.status === 403) {
      window.location.replace('/error-403');
    }
    if (error.response.status === 401 && error.config) {
      cookies.remove('token');
      window.location.replace('/auth/sign-in');
    }
    throw error;
  }
);
