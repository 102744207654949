import { create } from 'zustand';
import { PurchaseSteps } from './types';

interface PurchaseState {
  phone: string;
  loanRequestToken: string;
  creditLimit: string;
  currentStep: PurchaseSteps;
  isDocumentsNeeded: boolean;
  isSmsInfoEnabled: boolean;
  choosenTermId?: string;
  setChoosenTermId: (id: string) => void;
  setLoanRequestToken: (token: string) => void;
  setPurchaseStep: (step: PurchaseSteps) => void;
  setCreditLimit: (amount: string) => void;
  setIsSmsInfoEnabled: (value: boolean) => void;
  setIsDocumentsNeeded: (value: boolean) => void;
  updatePhone: (amount: string) => void;
  reset: () => void;
  clientPhoto: File[];
  setClientPhoto: (files: File[]) => void;

  chosenProductKind: 'factoring' | 'installments' | null;
  chooseProductKind: (productKind: 'factoring' | 'installments') => void;
}

const initialState = {
  loanRequestToken: '',
  creditLimit: '',
  isSmsInfoEnabled: false,
  phone: '',
  currentStep: PurchaseSteps.purchaseStart,
  isDocumentsNeeded: false,
  clientPhoto: [],
};

export const usePurchaseStore = create<PurchaseState>((set) => ({
  loanRequestToken: '',
  creditLimit: '',
  isSmsInfoEnabled: false,
  phone: '',
  currentStep: PurchaseSteps.purchaseStart,
  isDocumentsNeeded: false,
  updatePhone: (phone: string) => set((state) => ({ ...state, phone })),
  setLoanRequestToken: (token: string) => set((state) => ({ ...state, loanRequestToken: token })),
  setCreditLimit: (amount: string) => set((state) => ({ ...state, creditLimit: amount })),
  setIsDocumentsNeeded: (value: boolean) =>
    set((state) => ({ ...state, isDocumentsNeeded: value })),
  setPurchaseStep: (step: PurchaseSteps) => set((state) => ({ ...state, currentStep: step })),
  setIsSmsInfoEnabled: (value: boolean) => set((state) => ({ ...state, isSmsInfoEnabled: value })),
  setChoosenTermId: (id: string) => set((state) => ({ ...state, choosenTermId: id })),
  reset: () => {
    set(initialState);
  },
  clientPhoto: [],
  setClientPhoto: (files: File[]) => set((state) => ({ ...state, clientPhoto: files })),

  chosenProductKind: null,
  chooseProductKind: (productKind: 'factoring' | 'installments' | null) =>
    set((state) => ({ ...state, chosenProductKind: productKind })),
}));
